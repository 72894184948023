import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            {/** ¿Qué es? */}
            <div className="container lg:px-0 py-20">
                <Title>El Curs</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        La prova de nivell s'està convertint en decisiva any rere any per això volem
                        reforçar-vos amb un curs de molt nivell.
                        <br />
                        <br />
                        Us presentem un nou concepte de curs
                        <br />
                        <br />
                        Es tracta d'un curs intensiu i complet, que consta de 3 parts:
                        <br />
                        <br />
                        1. Accés immediat a la nostra plataforma online, on trobareu un curs
                        d'anglès que podreu anar completant al vostre ritme. Consta d'un total de 18
                        temes i 162 activitats i us ha de permetre aconseguir un bon fonament de
                        l'idioma, amb coneixements bàsics.
                        <br />
                        <br />
                        2. 21 hores de vídeos amb explicacions.
                        <br />
                        <br />
                        3. Classes setmanals d'1.5 hores de durada un dia la setmana, amb professor
                        especialitzat, agent de policia i més de deu anys d'experiència ensenyant
                        anglès.
                        <br />
                        <br />
                        Les classes tenen un nivell més avançat i s'estructuren d'acord amb les
                        oposicions anteriors amb diferents entorns de qüestions gramaticals que
                        s'han de conèixer per superar qualsevol examen d'anglès.
                        <br />
                        <br />
                        Totes les classes inclouen pràctica de vocabulari i gramàtica.
                        <br />
                        <br />
                        Totes les classes queden gravades perquè hi pugueu accedir, quan vulgueu, a
                        través de la plataforma online; a més, també hi haurà exercicis per
                        practicar cada setmana el que tractem a cada lliçó. Per tant, el curs val
                        molt la pena fins i tot si no podeu assistir a les classes en directe.
                        <br />
                        <br />
                        ESTRUCTURA DE LES CLASSES EN DIRECTE
                        <br />
                        <br />
                        - CLASSE 01. Dimecres, 27/04/2022, 19:00-20.30 h
                        <br />
                        <br />
                        - CLASSE 02. Dimecres, 4/05/2022, 19:00-20.30 h
                        <br />
                        <br />
                        - CLASSE 03. Dimarts, 10/05/2022, 19:00-21:00 h
                        <br />
                        <br />
                        + CLASSE FINAL DEDICADA A REPÀS I SIMULACRES D'EXAMEN AMB DIA I HORA A
                        DETERMINAR EN FUNCIÓ DE DATES D'EXAMEN
                        <br />
                        <br />
                        Us hi esperem a tots
                        <br />
                        <br />
                        Preu: 39,99 euros
                    </Text>
                </div>
            </div>

            <div className="bg-gray-100 bg-opacity-50">
                <div className="container lg:px-0 py-20">
                    <Title>¿Cómo accedo?</Title>
                    <Text className="text-justify">
                        Una vez adquirido a través de la sección{" "}
                        <Link to="/tienda/" className="default-link">
                            Tienda
                        </Link>{" "}
                        recibirás un correo con tus credenciales para poder acceder al curso. En el
                        menú superior encontrarás la opción{" "}
                        <a
                            href="https://cursos.iopos.es"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="default-link"
                        >
                            Plataforma de cursos
                        </a>{" "}
                        que te dirigirá hacia la plataforma donde están almacenados todos los cursos
                        y formaciones.
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
